export enum Environment {
  PRODUCTION = 'Production',
  SIMULATION = 'Simulation',
}

export enum Scenario {
  ACTUALS = 'Actuals',
  PLANNING = 'Planning',
}

export const scenarioOptions = [
  { label: Scenario.ACTUALS, value: '0' },
  { label: Scenario.PLANNING, value: '1' },
];

// TODO: Remove this after FAS API is ready
export enum Slices {
  SLICE1 = 'Slice1',
  SLICE2 = 'Slice2',
  SLICE3 = 'Slice3',
  SLICE4 = 'Slice4',
  SLICE5 = 'Slice5',
}

export const slicesOptions = [
  { label: Slices.SLICE1, value: '0' },
  { label: Slices.SLICE2, value: '1' },
  { label: Slices.SLICE3, value: '2' },
  { label: Slices.SLICE4, value: '3' },
  { label: Slices.SLICE5, value: '4' },
];
