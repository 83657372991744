/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import Container from '@cloudscape-design/components/container';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { FormField } from '@cloudscape-design/components';
import Select from '@cloudscape-design/components/select';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { SelectChangeEvent } from 'src/common/types/Events';
import { Environment, scenarioOptions } from 'src/common/constants/environment';
import { columnDefinitions } from 'src/common/components/driver-mapping-components/config/driver-mapping-table';
import { ShadowPnLDriverMapping } from 'src/common/types/DriverMapping';
import { filteringProperties } from 'src/common/components/driver-mapping-components/config/driver-mapping-filter-property';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import { ValueWithLabel } from 'src/common/components/ValueWithLabel';
import { ServerSideTable } from 'src/common/components/server-side-table/ServerSideTable';

interface OptionDefinition {
  label: string;
  value: string;
}

const ConfigureSliceAllocationMethodologyPage = () => {
  const { t } = useTranslation();

  // const hasConfigureSliceAdminPermission = useQuery({
  //     queryKey: [
  //         QueryKey.HasPagePermission,
  //         Action.UPDATE,
  //         PnLPage.CONFIGURE_SLICE_ALLOCATION_METHODOLOGY_PAGE,
  //     ],
  //     queryFn: () =>
  //         AuthServiceApi.hasPageAdminPermissions({
  //             action: Action.UPDATE,
  //             page: PnLPage.CONFIGURE_SLICE_ALLOCATION_METHODOLOGY_PAGE,
  //         }),
  //     enabled: true,
  // });

  // const isAdminUser = hasConfigureSliceAdminPermission.data?.isAuthorized === undefined;

  const [scenario, setSelectedScenario] = useState({
    label: scenarioOptions[0].label,
    value: scenarioOptions[0].value,
  });

  const [selectedSlice, setSelectedSlice] = useState<OptionDefinition>();

  const slicesByScenario = useQuery({
    queryKey: [QueryKey.GetSlicesByEnvironmentAndScenario, scenario],
    queryFn: () =>
      AllocationServiceApi.getSlicesByEnvironmentAndScenario({
        environment: Environment.PRODUCTION,
        scenario: scenario.value,
      }),
    enabled: Boolean(scenario),
  });

  const sliceOptions = useMemo(() => {
    if (slicesByScenario.data && Array.isArray(slicesByScenario.data)) {
      return slicesByScenario.data.map((slice) => ({
        label: slice.sliceName,
        value: slice.sliceId,
      }));
    }
    return [];
  }, [slicesByScenario.data]);

  const selectedSliceInformation = useQuery({
    queryKey: [QueryKey.GetSliceInformation, selectedSlice],
    queryFn: () =>
      AllocationServiceApi.getSliceInformation({
        sliceId: selectedSlice?.value,
      }),
    enabled: Boolean(selectedSlice),
  });
  const driverMappingId = selectedSliceInformation.data?.dmId;

  const colDefs = useMemo(() => columnDefinitions(t), [t]);
  function handleScenarioChange(event: SelectChangeEvent) {
    const index = event.detail.selectedOption.value === '0' ? 0 : 1;
    setSelectedScenario({
      label: scenarioOptions[index].label,
      value: scenarioOptions[index].value,
    });
  }

  function handleSliceChange(event: SelectChangeEvent) {
    const selectedSlice = event.detail.selectedOption;
    if (
      selectedSlice.label !== undefined &&
      selectedSlice.value !== undefined
    ) {
      setSelectedSlice({
        label: selectedSlice.label,
        value: selectedSlice.value,
      });
    }
  }

  const associatedSlicesWithSameDriverMappingId = useQuery({
    queryKey: [QueryKey.GetDownloadableDriverMapping, driverMappingId],
    queryFn: () =>
      AllocationServiceApi.getDownloadableDriverMappingPath({
        dmId: driverMappingId,
      }),
    enabled: Boolean(scenario && driverMappingId),
  });

  const associatedSlicesString = useMemo(() => {
    if (
      associatedSlicesWithSameDriverMappingId.data &&
      Array.isArray(associatedSlicesWithSameDriverMappingId.data)
    ) {
      return associatedSlicesWithSameDriverMappingId.data
        .map((slice) => slice.sliceName)
        .join(', ');
    }
    return '';
  }, [associatedSlicesWithSameDriverMappingId.data]);

  return (
    <div data-testid="slice-allocation-methodology-configure-page">
      <Container>
        <SpaceBetween size="xxl">
          <FormField label={t('scenario')} stretch>
            <Select
              data-testid="scenario"
              options={scenarioOptions}
              onChange={handleScenarioChange}
              selectedOption={scenario}
              placeholder={t('scenario_placeholder')}
            />
          </FormField>

          <FormField label={t('slice')} stretch>
            <Select
              data-testid="slice"
              options={sliceOptions}
              onChange={handleSliceChange}
              selectedOption={
                selectedSlice === undefined ? null : selectedSlice
              }
              placeholder={t('slice_placeholder')}
            />
          </FormField>

          <ValueWithLabel label={t('associated_slices')}>
            Slice 1, Slice 2 (place holder until there is data){' '}
            {associatedSlicesString}
          </ValueWithLabel>

          <>
            <ServerSideTable
              scenario={scenario.value}
              selectedSlice={selectedSlice?.value ?? ''}
              driverMappingId={driverMappingId ?? ''}
              columnDefinitions={colDefs}
              header={t('driver_mappings')}
              empty={{
                title: t('empty_driver_mappings'),
                description: t('empty_driver_mappings_description'),
              }}
              filteringProperties={filteringProperties(t)}
              testId="server-side-driver-mapping-table"
            />
          </>
        </SpaceBetween>
      </Container>
    </div>
  );
};

export default ConfigureSliceAllocationMethodologyPage;
