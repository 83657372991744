/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  lazy,
  startTransition,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table, { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { useTranslation } from 'react-i18next';
import {
  PropertyFilterProperty,
  useCollection,
} from '@amzn/awsui-collection-hooks';
import {
  CollectionPreferences,
  PropertyFilterProps,
} from '@amzn/awsui-components-react';
import Header from '@amzn/awsui-components-react/polaris/header';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import { TableProps as TableComponentProps } from '@cloudscape-design/components/table';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { Button } from '@cloudscape-design/components';
import { useQuery } from '@tanstack/react-query';
import {
  DriverMapping,
  GetDriverMappingsResponse,
} from '@amzn/allocations-service';
import {
  CONTENT_SELECTOR_OPTIONS,
  DEFAULT_PREFERENCES,
  PAGE_SELECTOR_OPTIONS,
  paginationLabels,
} from 'src/common/components/server-side-table/table-config';
import propertyFilterI18nStrings from 'src/common/constants/propertyFilterI18nStrings';
import { getTextFilterCounterText } from 'src/common/utils/table';
import { TableEmptyState } from 'src/common/components/TableEmptyState';
import { TableNoMatchState } from 'src/common/components/TableNoMatchState';
import { TableChangeEvent } from 'src/common/types/Events';
import { ShadowPnLDriverMapping } from 'src/common/types/DriverMapping';
import {
  DriverMappingAction,
  getDriverMappingData,
} from 'src/common/components/driver-mapping-components/utils/driverMapping';
import { downloadFile } from 'src/api/s3Api';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import { useNotificationContext } from 'src/common/provider/NotificationProvider';
import { Config, defaultConfig } from 'src/common/types/Config';
import { reducer } from 'src/common/utils/reducer';

const DriverMappingModal = lazy(
  () =>
    import(
      'src/common/components/driver-mapping-components/DriverMappingModal'
    ),
);

interface ServerSideTableProps {
  scenario: string;
  selectedSlice: string;
  driverMappingId: string;
  columnDefinitions: TableComponentProps.ColumnDefinition<ShadowPnLDriverMapping>[];
  header?: string;
  empty?: { title: string; description: string };
  filteringProperties?: PropertyFilterProperty[];
  testId: string;
}

export const ServerSideTable = (props: ServerSideTableProps) => {
  const { t } = useTranslation();

  const isPageAdminUser = true;
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [sortingState, setSortingState] = useState<
    TableProps.SortingState<ShadowPnLDriverMapping>
  >({
    sortingColumn: props.columnDefinitions[0],
  });

  const { addNotification } = useNotificationContext();

  const [isTableLoading, setIsTableLoading] = useState(true);

  // TODO: Replace this when new API is ready
  const currentDriverMappingData = useQuery({
    queryKey: [QueryKey.GetDriverMappings],
    queryFn: async () => {
      let lastEvaluatedKey: string | undefined = undefined;
      const mappings: DriverMapping[] = [];
      do {
        const response: GetDriverMappingsResponse =
          await AllocationServiceApi.getDriverMappings({
            taskIdentifier: '7e69b55d-2670-4d07-8f18-035398859f98',
            business: 'AB',
            region: 'IN',
            startKey: lastEvaluatedKey,
          });
        mappings.push(...(response.driverMappings ?? []));
        lastEvaluatedKey = response.lastEvaluatedKey;
      } while (lastEvaluatedKey);
      return mappings;
    },
    select: (data) =>
      data?.map<ShadowPnLDriverMapping>((driverMapping) => ({
        ...driverMapping,
        accountExclusions: driverMapping.accountExclusions ?? [],
        costCenterExclusions: driverMapping.costCenterExclusions ?? [],
        companyCodeExclusions: driverMapping.companyCodeExclusions ?? [],
        locationCodeExclusions: driverMapping.locationCodeExclusions ?? [],
        productCodeExclusions: driverMapping.productCodeExclusions ?? [],
        projectCodeExclusions: driverMapping.projectCodeExclusions ?? [],
        channelCodeExclusions: driverMapping.channelCodeExclusions ?? [],
        primaryDriver: driverMapping.drivers?.[0],
        secondaryDriver: driverMapping.drivers?.[1],
        fallbackDriver: driverMapping.drivers?.[2],
        allocationFunction: driverMapping.allocationFunctions?.[0],
      })),
    enabled: Boolean(props.scenario),
  });
  const paginatedItems = currentDriverMappingData.data ?? [];

  const {
    items,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(paginatedItems, {
    propertyFiltering: {
      filteringProperties: props.filteringProperties ?? [],
      empty: props.empty ? (
        <TableEmptyState
          title={props.empty.title}
          subtitle={props.empty.description}
        />
      ) : undefined,
      noMatch: <TableNoMatchState />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      ...(sortingState && { defaultState: sortingState }),
    },
    selection: {},
  });

  const [selectedItems, setSelectedItems] = useState<ShadowPnLDriverMapping[]>(
    [],
  );
  const [descendingSorting, setDescendingSorting] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [filteringText, setFilteringText] = useState('');
  const [delayedFilteringText, setDelayedFilteringText] = useState('');
  const [sortingColumn, setSortingColumn] = useState(
    props.columnDefinitions[0],
  );

  const [isUpdating, setIsUpdating] = useState(false);

  const [filteringOptions, setFilteringOptions] = useState<
    readonly PropertyFilterProps.FilteringOption[]
  >([]);

  const [filteringQuery, setFilteringQuery] =
    React.useState<PropertyFilterProps.Query>({
      tokens: [],
      operation: 'and',
    });

  // Reset the page index once the pagination setting changes
  useEffect(() => {
    setCurrentPageIndex(1);
  }, [preferences.pageSize, filteringQuery]);

  const { pageSize } = preferences;

  const params = {
    pagination: {
      currentPageIndex,
      pageSize,
    },
    sorting: {
      sortingColumn,
      sortingDescending: descendingSorting,
    },
    filtering: {
      filteringText: delayedFilteringText,
    },
  };

  const onClearFilter = () => {
    setFilteringText('');
    setDelayedFilteringText('');
  };

  const handleSelectionChange = ({
    detail,
  }: TableChangeEvent<ShadowPnLDriverMapping>) => {
    setSelectedItems(detail.selectedItems);
  };

  // Reset the page index once the pagination setting changes
  useEffect(() => {
    setCurrentPageIndex(1);
  }, [preferences.pageSize, filteringQuery]);

  useEffect(() => {
    if (!currentDriverMappingData.data) {
      return;
    }
    setIsTableLoading(false);
  }, [currentDriverMappingData.data]);

  // TODO: Test this method when API is ready
  const getCurrentDateTime = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  const downloadablePath = useQuery({
    queryKey: [QueryKey.GetDownloadableDriverMapping, props.driverMappingId],
    queryFn: () =>
      AllocationServiceApi.getDownloadableDriverMappingPath({
        dmId: props.driverMappingId,
      }),
    enabled: Boolean(props.scenario && props.driverMappingId),
  });

  const handleDownload = async () => {
    try {
      const currentDateTime = getCurrentDateTime();
      await downloadFile({
        name: `${props.scenario}-${props.selectedSlice}-${currentDateTime}.json`,
        path:
          downloadablePath.data?.downloadUrl === undefined
            ? ''
            : downloadablePath.data?.downloadUrl,
        bucket: 'allocations-driver-mapping-beta',
      });
    } catch {
      addNotification({
        type: 'error',
        content: t('driver_mapping_download_error'),
      });
    }
  };

  const [config, dispatch] = useReducer(reducer, defaultConfig);
  const handleConfigChange = useCallback(
    (action: { type: string; payload?: Partial<Config> }) => {
      dispatch({
        type: action.type,
        payload: { ...action.payload, error: {} },
      });

      setIsUpdating(true);
    },
    [],
  );

  const handleDelete = () => {
    handleConfigChange({
      type: 'UPDATE',
      payload: {
        driverMappings: getDriverMappingData(currentDriverMappingData.data, {
          operation: DriverMappingAction.DELETE,
          filter: selectedItems[0],
        }),
      },
    });
  };

  const [driverMappingModalAction, setDriverMappingModalAction] =
    useState<DriverMappingAction>();
  const toggleModalDisplay = (action?: DriverMappingAction) => {
    startTransition(() => setDriverMappingModalAction(action));
  };

  function handleUpdateDriverMapping(): void {
    throw new Error('Function not implemented.');
  }

  function handleCancelDriverMapping(): void {
    throw new Error('Function not implemented.');
  }

  const handleSubmit = (newDriverMappings: DriverMapping[]) => {
    let driverMappings: DriverMapping[] = [];
    if (driverMappingModalAction === DriverMappingAction.EDIT) {
      driverMappings = getDriverMappingData(currentDriverMappingData.data, {
        filter: selectedItems[0],
        operation: DriverMappingAction.EDIT,
        driverMappings: newDriverMappings,
      });
    } else {
      driverMappings = getDriverMappingData(currentDriverMappingData.data, {
        operation: DriverMappingAction.ADD,
        driverMappings: newDriverMappings,
      });
    }
    handleConfigChange({ type: 'UPDATE', payload: { driverMappings } });
    setSelectedItems([]);
    toggleModalDisplay();
  };

  // const fetchData = (filteringText: string, filterKey: string) => {
  //     try {
  //         // items to get options
  //         if (filterKey === WorkloadUnitFilterName.workloadUnitIds) {
  //             const unitIdValues = items.map((item) => item.unitId);
  //             setFilteringOptions(
  //                 getOptions(
  //                     unitIdValues,
  //                     filteringText,
  //                     WorkloadUnitFilterName.workloadUnitIds
  //                 )
  //             );
  //         } else {
  //             setFilteringOptions([]);
  //         }
  //         setStatus("finished");
  //     } catch (error) {
  //         setStatus("error");
  //     }
  // };

  // const handleLoadItems: NonCancelableEventHandler<
  //     PropertyFilterProps.LoadItemsDetail
  // > = ({ detail: { filteringText, filteringProperty, firstPage } }) => {
  //     if (!filteringProperty) {
  //         return;
  //     }
  //     // setStatus("loading");
  //     if (firstPage) {
  //         setFilteringOptions([]);
  //     }
  //     // fetchData(filteringText, filteringProperty.key);
  // };

  // const onSortingChange = (event: { detail: { isDescending: boolean | ((prevState: boolean) => boolean); sortingColumn: React.SetStateAction<TableComponentProps.ColumnDefinition<ShadowPnLDriverMapping>>; }; }) => {
  //     setDescendingSorting(event.detail.isDescending);
  //     setSortingColumn(event.detail.sortingColumn);
  // };

  function handleLoadItems() {}

  return (
    <>
      <>
        {Boolean(driverMappingModalAction) && (
          <DriverMappingModal
            config={config}
            driverMapping={
              driverMappingModalAction === DriverMappingAction.EDIT
                ? selectedItems[0]
                : undefined
            }
            onClose={() => toggleModalDisplay()}
            onSubmit={handleSubmit}
            visible={Boolean(driverMappingModalAction)}
          />
        )}
      </>
      <Table
        data-testid={props.testId}
        {...collectionProps}
        enableKeyboardNavigation={true}
        loading={isTableLoading}
        selectedItems={selectedItems}
        items={items}
        onSortingChange={({ detail }) => setSortingState(detail)}
        onSelectionChange={handleSelectionChange}
        sortingColumn={sortingColumn}
        sortingDescending={descendingSorting}
        columnDefinitions={props.columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        selectionType="multi"
        variant="full-page"
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        stickyColumns={preferences.stickyColumns}
        header={
          <Header
            variant="awsui-h1-sticky"
            counter={
              selectedItems?.length
                ? `(${selectedItems.length}/${paginatedItems.length}+)`
                : `(${paginatedItems.length}+)`
            }
            actions={
              isPageAdminUser && (
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    data-testid="download-driver-mapping"
                    onClick={handleDownload}
                  >
                    {t('download_driver_mapping')}
                  </Button>
                  <Button
                    disabled={!selectedItems.length}
                    data-testid="delete-driver-mapping"
                    onClick={handleDelete}
                  >
                    {t('delete_driver_mapping')}
                  </Button>
                  <Button
                    disabled={!selectedItems.length}
                    data-testid="edit-driver-mapping"
                    onClick={() => toggleModalDisplay(DriverMappingAction.EDIT)}
                  >
                    {t('edit_driver_mapping')}
                  </Button>
                  <Button
                    variant="primary"
                    data-testid="add-driver-mapping"
                    onClick={() => toggleModalDisplay(DriverMappingAction.ADD)}
                  >
                    {t('add_driver_mapping')}
                  </Button>
                </SpaceBetween>
              )
            }
          >
            {props.header}
          </Header>
        }
        loadingText={t('table_loading')}
        filter={
          props.filteringProperties ? (
            <PropertyFilter
              i18nStrings={propertyFilterI18nStrings(t)}
              {...propertyFilterProps}
              countText={getTextFilterCounterText(filteredItemsCount, t)}
              expandToViewport={true}
              onChange={({ detail }) => setFilteringQuery(detail)}
              onLoadItems={handleLoadItems}
            />
          ) : undefined
        }
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        preferences={
          <CollectionPreferences
            preferences={preferences}
            onConfirm={(event) => setPreferences(event.detail)}
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            visibleContentPreference={{
              title: 'Select visible columns',
              options: CONTENT_SELECTOR_OPTIONS,
            }}
            pageSizePreference={{
              title: 'Page size',
              options: PAGE_SELECTOR_OPTIONS,
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Check to see all the text and wrap the lines',
            }}
          />
        }
        footer={
          <SpaceBetween direction="horizontal" size="xs" alignItems="end">
            <Button
              variant="primary"
              disabled={!selectedItems.length}
              data-testid="update-driver-mapping"
              onClick={handleUpdateDriverMapping}
            >
              {t('update_driver_mapping')}
            </Button>
            <Button
              variant="normal"
              disabled={!selectedItems.length}
              data-testid="cancel-driver-mapping"
              onClick={handleCancelDriverMapping}
            >
              {t('cancel_driver_mapping')}
            </Button>
          </SpaceBetween>
        }
      />
    </>
  );
};
